import $ from 'jquery';
import {TweenMax, TimelineMax, CSSPlugin, Draggable, AttrPlugin, ScrollToPlugin, Expo, Power2} from "gsap/all";
import {DrawSVGPlugin} from "./gsap-bonus/DrawSVGPlugin";
import {SplitText} from "./gsap-bonus/SplitText";
import {MorphSVGPlugin} from "./gsap-bonus/MorphSVGPlugin";



window.jQuery = $;
window.$ = $;
const plugins = [Draggable, TweenMax, CSSPlugin, DrawSVGPlugin, SplitText, MorphSVGPlugin, AttrPlugin, ScrollToPlugin],
    homepage = document.getElementById("homepage");

if (homepage) {
    var preloadAnim = new TimelineMax({
        onStart: () => {
            let myScript = document.createElement("script"),
                url = document.getElementById("lazyLoadScript").getAttribute("data-src");

            myScript.src = url;
            myScript.className = 'lazy-script';
            setTimeout(() => {
                document.body.appendChild(myScript);
            }, 1000);
        }
    });

    preloadAnim
        .to(".preloader__inner", 3, {scaleX: 0, transformOrigin: "right top", ease: Expo.easeInOut})
        .to("#preload-svg", 3, {scale: 230, transformOrigin: "50% 50%", ease: Power2.easeOut})
        .to(".preloader", 0.1, {autoAlpha: 0}, "-=2.3")
        .addLabel("first-screen", "-=1.2")
        .set(".preloader", {scale: 0,})
        .fromTo(".sliding-text--first-screen", 0.8, {autoAlpha: 0, y: -10}, {autoAlpha: 1, y: 0}, "first-screen")
        .to(".sliding-text--first-screen", 0, {className: "-=active"}, "first-screen+=0.2")
        .fromTo(".header__logo-link", 0.8, {autoAlpha: 0, y: -10}, {autoAlpha: 1, y: 0}, "first-screen")

        .fromTo(".header__actual", 0.8, {autoAlpha: 0, y: -10}, {autoAlpha: 1, y: 0}, "first-screen+=0.9")

        .fromTo(".button-down", 0.8, {autoAlpha: 0}, {autoAlpha: 1}, "first-screen+=1.1")
        .fromTo(".welcome-screen__letter-title", 0.8, {autoAlpha: 0, y: 10}, {
            autoAlpha: 1,
            y: 0
        }, "first-screen+=1.2")
        .fromTo(".welcome-screen__bottom-link", 0.8, {autoAlpha: 0, y: 10}, {
            autoAlpha: 1,
            y: 0
        }, "first-screen+=1.2")
        .fromTo(".welcome-screen__bottom-cta-overlay", 0.8, {
            scaleY: 1,
            transformOrigin: "50% 100%"
        }, {scaleY: 0}, "first-screen+=1.3")
        .fromTo(".welcome-screen__bottom-text", 0.8, {autoAlpha: 0, y: 10}, {
            autoAlpha: 1,
            y: 0
        }, "first-screen+=1.2")
    ;
    if ($(window).width() > 768) {
        preloadAnim
            .fromTo(".header__telephones", 0.8, {autoAlpha: 0, y: -10}, {autoAlpha: 1, y: 0}, "first-screen+=0.2")
            .fromTo(".header__nav", 0.8, {autoAlpha: 0}, {
                autoAlpha: 1
            }, "first-screen+=1");
    } else {
        preloadAnim
            .fromTo(".header__menu-btn", 0.8, {autoAlpha: 0, y: -10}, {autoAlpha: 1, y: 0}, "first-screen+=0.2")
    }
} else {
    let myScript = document.createElement("script"),
        url = document.getElementById("lazyLoadScript").getAttribute("data-src");

    myScript.src = url;
    myScript.className = 'lazy-script';
    document.body.appendChild(myScript);
}
